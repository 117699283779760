import React from 'react'

const Security = () => {
 return (
  <div className='px-3 pt-[40px]'>
   <p className='font-poppins font-medium text-xs'>
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
    deserunt mollit anim id es Lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minim
    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo con Duis aute irure dolor in reprehenderit in voluptate velit esse
    cillum dolore eu fugiat nulla pariatu Excepteur sint occaecat cupidatat non
    proident, sunt in culpa qui officia deserunt mollit anim id es Sed ut
    perspiciatis unde omnis iste natus error sit voluptatem accusantium
    doloremque laudantium, to Nemo enim ipsam voluptatem quia voluptas sit
    aspernatur aut odit aut fugit, sed quia consequuntur ma Neque porro quisquam
    est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
    qu Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
    suscipit laboriosam, nisi ut al Quis autem vel eum iure reprehenderit qui in
    ea voluptate velit esse quam nihil molestiae consequatu
   </p>
  </div>
 )
}

export default Security
